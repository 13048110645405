import React from 'react'

import { Box } from 'system'

export const TapaPatternFill = props => (
    <Box
        backgroundImage='url("/images/tapa-reverse.svg")'
        backgroundSize="10rem"
        bottom={0}
        left={0}
        opacity={0.075}
        position="absolute"
        right={0}
        top={0}
        css={`
            pointer-events: none;
        `}
        {...props}
    />
)
