import React from 'react'

import { BoundedBox } from 'src/components/BoundedBox'
import { ButtonArrow } from 'src/components/ButtonArrow'
import { Heading } from 'src/components/Heading'
import { MarkdownContent } from 'src/components/MarkdownContent'
import { TwoColumnContent } from 'src/components/TwoColumnContent'

export const BodyCopy = ({
  heading,
  markdown,
  backTo,
  backText = 'Back',
  ...props
}) => {
  return (
    <BoundedBox as="section" maxWidth={heading ? 'large' : 'medium'} {...props}>
      <TwoColumnContent
        renderLeft={
          heading && (() => <Heading color="teal">{heading}</Heading>)
        }
        renderRight={() => (
          <>
            <MarkdownContent
              markdown={markdown}
              lineHeight="wide"
              color="navy"
            />
            {backTo && (
              <ButtonArrow to={backTo} direction="left" mt={4}>
                {backText}
              </ButtonArrow>
            )}
          </>
        )}
      />
    </BoundedBox>
  )
}
