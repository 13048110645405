import React from 'react'

import { Box, Flex } from 'system'

export const TwoColumnContent = ({ renderLeft, renderRight, ...props }) => (
  <Flex flexDirection={['column', 'row']} justifyContent="center">
    {renderLeft && (
      <Box width={[1, 4 / 12]} mr={[null, 4]} mb={[4, 0]}>
        {renderLeft()}
      </Box>
    )}
    {renderRight && (
      <Box width={[1, renderLeft ? 8 / 12 : 1]} pt={[null, renderLeft && 1]}>
        {renderRight()}
      </Box>
    )}
  </Flex>
)
