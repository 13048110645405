import React from 'react'

import { Link } from 'system'

export const Anchor = props => (
  <Link
    css={`
      text-decoration: underline;
    `}
    {...props}
  />
)
