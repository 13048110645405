import React from 'react'
import { get } from 'lodash/fp'

import { GatsbyImage, GatsbyImageContainer } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { Heading } from 'src/components/Heading'
import { TapaPatternFloating } from 'src/components/TapaPatternFloating'

const themes = {
  sand: {
    bg: 'sandExtraLight',
    bgOpacity: 1,
    color: 'navy',
  },
  teal: {
    bg: 'teal',
    bgOpacity: 0.1,
    color: 'white',
  },
}

export const PageTitle = ({
  children,
  imageFluid,
  theme = 'sand',
  ...props
}) => (
  <BoundedBox
    as="section"
    bg={get([theme, 'bg'], themes)}
    color={get([theme, 'color'], themes)}
    position="relative"
    pt={imageFluid ? [10, 20] : [6, 10]}
    pb={imageFluid ? [10, 20] : [6, 10]}
    {...props}
  >
    {imageFluid ? (
      <GatsbyImageContainer
        bg="black"
        left={0}
        position="absolute"
        top={0}
        bottom={0}
        right={0}
      >
        <GatsbyImage
          fluid={imageFluid}
          alt=""
          height="100%"
          width="100%"
          opacity={0.4}
          css={`
            pointer-events: none;
            object-fit: cover;
          `}
        />
      </GatsbyImageContainer>
    ) : (
      <TapaPatternFloating opacity={get([theme, 'bgOpacity'], themes)} />
    )}
    <Heading
      color={imageFluid ? 'yellow' : 'inherit'}
      fontSize={['large', 'xxlarge']}
      textAlign="center"
      position="relative"
    >
      {children}
    </Heading>
  </BoundedBox>
)
