import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { get } from 'lodash/fp'

import { Layout } from 'src/components/Layout'

import { BodyCopy } from 'src/slices/BodyCopy'
import { NotificationBar } from 'src/slices/NotificationBar'
import { PageTitle } from 'src/slices/PageTitle'
import { Summary } from 'src/slices/Summary'

const PageTemplate = ({ data }) => {
  const page = get('markdownRemark', data)

  return (
    <Layout>
      <Helmet>
        <title>{get('frontmatter.title', page)}</title>
      </Helmet>
      <PageTitle
        imageFluid={get('frontmatter.image.childImageSharp.fluid', page)}
      >
        {get('frontmatter.title', page)}
      </PageTitle>
      {get('frontmatter.notice', page) && (
        <NotificationBar>{get('frontmatter.notice', page)}</NotificationBar>
      )}
      {get('frontmatter.description', page) && (
        <Summary markdown={get('frontmatter.description')} />
      )}
      <BodyCopy
        heading={get('frontmatter.subtitle', page)}
        markdown={get('rawMarkdownBody', page)}
        backTo={get('frontmatter.back_button_url', page)}
      />
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query PageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        notice
        description
        image {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        subtitle
        backButtonUrl: back_button_url
      }
      rawMarkdownBody
    }
  }
`
