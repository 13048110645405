import React from 'react'

import { Box, Text, Markdown } from 'system'
import { Anchor } from 'src/components/Anchor'
import { Subheading } from 'src/components/Subheading'

const baseHeadingProps = {
  mt: 4,
  mb: 2,
  boxStyle: 'firstLastNoMargin',
}

const defaultMap = {
  h1: props => <Subheading as="h3" {...baseHeadingProps} {...props} />,
  h2: props => (
    <Subheading
      as="h4"
      {...baseHeadingProps}
      color="navy"
      fontSize="medium"
      mb={1}
      {...props}
    />
  ),
  h3: props => <Subheading as="h5" {...baseHeadingProps} {...props} />,
  h4: props => <Subheading as="h6" {...baseHeadingProps} {...props} />,
  h5: props => <Subheading as="h6" {...baseHeadingProps} {...props} />,
  h6: props => <Subheading as="h6" {...baseHeadingProps} {...props} />,
  p: props => <Text mb={2} boxStyle="lastNoMargin" {...props} />,
  ul: props => <Box as="ul" mb={2} pl={3} boxStyle="lastNoMargin" {...props} />,
  li: props => (
    <Text
      as="li"
      css={`
        display: list-item;
        list-style: disc;
      `}
      {...props}
    />
  ),
  a: props => <Anchor {...props} />,
}

export const MarkdownContent = ({ markdown, map, ...props }) => (
  <Text as="div" lineHeight="wide" {...props}>
    <Markdown markdown={markdown} map={{ ...defaultMap, ...map }} />
  </Text>
)
