import React from 'react'

import { Flex, Text, Link, SVG } from 'system'
import { IconChevronLeft } from 'src/components/IconChevronLeft'
import { IconChevronRight } from 'src/components/IconChevronRight'

export const ButtonArrow = ({
  color = 'navy',
  direction = 'right',
  children,
  ...props
}) => (
  <Flex
    as={Link}
    display="inline-flex"
    alignItems="center"
    color={color}
    css={`
      &:hover {
        .arrow {
          transform: translateX(${direction === 'right' ? '25%' : '-25%'});
        }
      }
    `}
    {...props}
  >
    <Text
      as="span"
      fontFamily="sansCond"
      fontSize={['medium', 'midLarge']}
      fontWeight="bold"
      lineHeight="solid"
      order={direction === 'right' ? 1 : 2}
      textStyle="smallCaps"
    >
      {children}
    </Text>
    <SVG
      svg={direction === 'right' ? IconChevronRight : IconChevronLeft}
      fill={color}
      x={11}
      y={18}
      className="arrow"
      ml={direction === 'right' ? [1, 2] : 0}
      mr={direction === 'right' ? 0 : [1, 2]}
      order={direction === 'right' ? 2 : 1}
      width={['0.6rem', '0.75rem']}
      css={`
        transition: transform ${p => p.theme.transition};
      `}
    />
  </Flex>
)
