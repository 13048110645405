import React from 'react'

import { Text } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'

export const NotificationBar = ({ children, html, ...props }) => {
  const styles = {
    color: 'navy',
    fontSize: 'midLarge',
    fontStyle: 'italic',
    textAlign: 'center',
  }

  return (
    <BoundedBox
      as="section"
      bg="yellow"
      maxWidth="large"
      pb={2}
      pt={2}
      {...props}
    >
      {html ? (
        <Text
          {...styles}
          css={{
            a: {
              textDecoration: 'underline',
            },
          }}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      ) : (
        <Text {...styles}>{children}</Text>
      )}
    </BoundedBox>
  )
}
