import React from 'react'

import { Subheading } from 'src/components/Subheading'
import { BoundedBox } from 'src/components/BoundedBox'
import { MarkdownContent } from 'src/components/MarkdownContent'
import { TapaPatternFill } from 'src/components/TapaPatternFill'

export const Summary = ({ children, markdown, ...props }) => {
  return (
    <BoundedBox
      as="section"
      bg="teal"
      color="white"
      maxWidth="large"
      position="relative"
      {...props}
    >
      <TapaPatternFill />
      {children || (
        <MarkdownContent
          markdown={markdown}
          map={{
            h1: props => (
              <Subheading as="h3" color="yellow" mb={2} {...props} />
            ),
          }}
          fontSize={['midLarge', 'large']}
          fontWeight={['normal', 'light']}
          lineHeight="copy"
          textAlign="center"
          position="relative"
        />
      )}
    </BoundedBox>
  )
}
