import React from 'react'

export const IconChevronLeft = props => {
  return (
    <svg viewBox="0 0 11 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m0 15.9 6.9-6.9-6.9-7 2-2 9 9-9 8.9z"
        fill-rule="evenodd"
        transform="matrix(-1 0 0 1 11 0)"
      />
    </svg>
  )
}
